import { ButtonProps } from '@design-system/src/components/Button'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import type { SystemStyleObject } from 'styled-system/types'

import { StripeAccountType, SubscriptionPromo } from '@models/types'
import { WysiwygElement } from '@models/wysiwyg-types'

import { PlanCard, StripePlanFrequencyLabel } from '../SubscibeShared/PlanCard'
import { onSubscribeToStripePlanFactory } from './subscribe-stripe-utils'

export type StripePlanCard = {
  planSummary?: string
  benefits?: WysiwygElement[]
  priceSubtitle?: string
  isTopChoice: boolean
  name?: string
  subtitle?: string
  priceForYearlyToggleChoice?: number
  priceForMonthlyToggleChoice?: number
  struckOutPriceForYearlyToggleChoice?: number | null
  struckOutpriceForMonthlyToggleChoice?: number | null
  stripePriceIdMonthly: string
  stripePriceIdYearly: string
  productId: string
  userHasASubscription: boolean
  planType: 'yearly' | 'monthly'
  css?: SystemStyleObject
  loadStripe: BlockPubSubscribeCustomPageData['loadStripe']
  createSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['createSubscriberWithStripeAccountApiUrl']
  updateSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['updateSubscriberWithStripeAccountApiUrl']
  status: BlockPubSubscribeCustomPageData['status']
  stripePublishableKey: BlockPubSubscribeCustomPageData['stripePublishableKey']
  userIsSubscribedToPlanAndPrice: boolean
  totalNumberOfPlans: 1 | 2 | 3
  showYearlySavingsInCents: boolean
  clickSubscribeLinkOverride?: string
  promo?: SubscriptionPromo
  siteType: StripeAccountType
  // handleSubscribeToPlan: (props:{email?: string, plan: PropaySubscriptionPlan |SubscriptionPlan}) => Promise<void>
}

interface IFormInput {
  email: string
}

export const StripePlanCard: React.FC<StripePlanCard> = ({
  benefits,
  css: cssProp = {},
  updateSubscriberWithStripeAccountApiUrl,
  priceSubtitle,
  isTopChoice,
  name,
  subtitle,
  priceForYearlyToggleChoice,
  priceForMonthlyToggleChoice,
  struckOutpriceForMonthlyToggleChoice,
  struckOutPriceForYearlyToggleChoice,
  planSummary,
  planType,
  stripePriceIdMonthly,
  stripePriceIdYearly,
  userHasASubscription,
  createSubscriberWithStripeAccountApiUrl,
  status,
  stripePublishableKey,
  loadStripe,
  userIsSubscribedToPlanAndPrice,
  totalNumberOfPlans,
  showYearlySavingsInCents,
  clickSubscribeLinkOverride,
  promo,
  siteType,
}) => {
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const [errorMsg, setErrorMsg] = useState('')

  let planPrice = planType === 'yearly' ? priceForYearlyToggleChoice : priceForMonthlyToggleChoice
  let planStruckOutPrice =
    planType === 'yearly' ? struckOutPriceForYearlyToggleChoice : struckOutpriceForMonthlyToggleChoice
  let planFrequencyLabel: StripePlanFrequencyLabel = planType === 'yearly' ? 'Year' : 'Month'
  let priceId = planType === 'yearly' ? stripePriceIdYearly : stripePriceIdMonthly

  const yearlySavingsInCents =
    showYearlySavingsInCents && planType === 'yearly' && priceForMonthlyToggleChoice && priceForYearlyToggleChoice
      ? priceForMonthlyToggleChoice * 12 - priceForYearlyToggleChoice
      : undefined

  const promoPrice = planPrice && promo?.percentageOff ? planPrice * (1 - promo?.percentageOff / 100) : undefined

  const onSubscribeToPlan = onSubscribeToStripePlanFactory({
    userHasASubscription,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    loadStripe,
    setSubmitBtnState,
    priceId,
    setErrorMsg,
    couponId: promo?.couponId,
  })

  const onSubmitEmail: SubmitHandler<IFormInput> = async formData => {
    const email = formData.email
    onSubscribeToPlan({ email })
  }

  const promoText = getPromoText({ promo, planFrequencyLabel })

  if (!planPrice) return null

  return (
    <PlanCard
      clickSubscribeLinkOverride={clickSubscribeLinkOverride}
      benefits={benefits}
      css={cssProp}
      priceSubtitle={priceSubtitle}
      isTopChoice={isTopChoice}
      name={name}
      subtitle={subtitle}
      planSummary={planSummary}
      userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
      onSubmitUserInfo={onSubmitEmail}
      onSubscribeToPlan={onSubscribeToPlan}
      errorMsg={errorMsg}
      subscriptionInProgress={submitBtnState === 'waiting'}
      planPrice={promoPrice || planPrice}
      planStruckOutPrice={planStruckOutPrice || (promoPrice ? planPrice : undefined)}
      planFrequencyLabel={planFrequencyLabel}
      isUserUnauthenticated={status === 'unauthenticated'}
      planRequiresShipping={false}
      totalNumberOfPlans={totalNumberOfPlans}
      secondaryFrequencyLabel="month"
      yearlySavingsInCents={yearlySavingsInCents}
      promoText={promoText}
      planCardType={siteType === 'donation' ? 'donation' : 'subscription'}
    />
  )
}

const getPromoText = ({
  promo,
  planFrequencyLabel,
}: {
  promo?: SubscriptionPromo
  planFrequencyLabel: StripePlanFrequencyLabel
}) => {
  if (!promo) return undefined
  const duration = planFrequencyLabel === 'Year' ? 'year' : `${promo.durationInMonths} months`
  return `* Enjoy ${promo.percentageOff}% savings for the first ${duration}`
}
